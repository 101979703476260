import React, { useState } from 'react';

import moment from 'moment';
import Cookies from 'js-cookie';
import { Button } from 'reactstrap';
import Logo from './logo.png'

import { provinceTaxes } from '../../constants/taxes';
import { Container, Row, Col, ApiUrl, toast, Axios, Modal, ModalHeader, ModalBody, ModalFooter } from '../index';

export default function ReviewModal({ modal, toggle, deliveries, isAfter7pm, deliveryCharges, redirectPage }) {
  const [loading, setLoading] = useState(false);

  var total = deliveryCharges.reduce((sum, { fee }) => sum + Number(getPriceWithoutTax(fee)), 0);

  // const total = 700 + 1250 + 900;

  const invalidDeliveryCharge = deliveryCharges.some(({ fee }) => fee === 0);

  const submitOrders = async (printLabel = false) => {
    const { API_SUBMIT_ORDERS } = ApiUrl;

    const orders = deliveries.map((delivery, idx) => {
      // change prescription numbers to array
      const prescriptionNumberKeys = [];
      const nop = Number(delivery.numberOfPrescriptions);

      for (let index = 0; index < nop; index++) {
        prescriptionNumberKeys.push(`prescriptionNumber${index + 1}`);
      }

      if (delivery.recurring && delivery.recurring.length > 0 && !(delivery.ends && delivery.ends.length > 0)) {
        // add 6 month to today and add that as end date
        const endDate = moment().add(6, 'months');

        delivery.ends = ['ends'];
        delivery.endDate = endDate.toISOString();
      }

      return {
        unitNumber: delivery.unitNumber,
        cellPhoneNumber: delivery.phone,
        address: delivery.address.address,
        zipCode: delivery.address.zipCode,
        patientName: delivery.patientName,
        apptOrUnitType: delivery.apptOrUnitType,
        apptOrUnit: delivery.apptOrUnit,
        deliveryNotes: delivery.deliveryNotes,
        pickupNotes: delivery.pickupNotes,
        orderSubTotal: deliveryCharges[idx].fee,
        pharmacyId: Cookies.get('_id'),
        proofOfDelivery: delivery.proofOfDelivery,
        proofOfReturnDelivery: delivery.proofOfReturnDelivery,
        proofOfPickup: delivery.proofOfPickup,
        numberOfPrescriptions: delivery.numberOfPrescriptions,
        prescriptionNumbers: prescriptionNumberKeys.map((prescriptionNumberKey) => delivery[prescriptionNumberKey]),

        // order schedule
        scheduled: delivery.scheduled,
        scheduledDate: delivery.scheduledDate,

        nextRecurrenceDate: delivery.nextRecurrenceDate,

        recurring: delivery.recurring,
        recurrence: delivery.recursion,
        customRecurringDays: delivery.customRecurringDays,
        customRecurringDaysOrderTime: delivery.customRecurringDaysOrderTime,

        ends: delivery.ends,
        endDate: delivery.endDate,
        isDeliverToPharmacy: delivery.isDeliverToPharmacy,

        isAfter7pm,
      };
    });

    try {
      setLoading(true);

      const res = await Axios.post(API_SUBMIT_ORDERS, { orders });

      const { data } = res;
      const createdOrderResponse = data.data

      if (data.status === 'success') {
        toast.success('ORDER CREATED SUCCESSFULLY');
        if (printLabel) {
          // Label which pharmacies can print a small one with patient name, Patient address, Phone number, Delivery/dropoff notes, type of droppoff delivery (signature or picture)
          const printData = []
          deliveries.map((delivery, idx) => {
            const addressWithApptOrUnit = delivery.apptOrUnit ? `${delivery.address.address}, ${delivery.apptOrUnitType || "Unit"} ${delivery.apptOrUnit}` : delivery.address.address;

            printData.push({
              ...delivery,
              patientName: delivery.patientName,
              address: addressWithApptOrUnit,
              phone: formatNumberCanadian(delivery.phone),
              deliveryNotes: delivery.deliveryNotes,
              // match with delivery.patientName with createdOrderResponse[index].patientName if match then add createdOrderResponse[].storeOrderId
              storeOrderId: createdOrderResponse.find((order) => order.customerName === delivery.patientName).storeOrderId,
              proofOfDelivery: delivery.proofOfDelivery === "rx" ? 'Signature' : 'Picture',
              isDeliverToPharmacy: delivery.isDeliverToPharmacy ? 'Delivery' : 'Dropoff',
              numberOfPrescriptions: delivery.numberOfPrescriptions,
            })
          })
          // Open print page popup with printData
          let printWindow = window.open('', '_blank', 'width=650,height=400');
          printWindow.document.write('<html><head><title>Shipping Label</title></head><body>');
          printWindow.document.write('<style>body { margin: 0; font-size: 24px; } .label { height: 100vh; border: 1px solid black; padding: 20px; box-sizing: border-box; position: relative; } .bold { font-weight: bold; } .logo { position: absolute; bottom: 10px; right: 10px; width: 180px; } .pharmacy { text-align: center; font-size: 28px; } h2 { font-size: 32px; } h3 { font-size: 29px; } p { font-size: 26px; } @media print { @page { size: landscape; } }</style>');

          printData.forEach((data) => {
            printWindow.document.write('<div class="label">');
            printWindow.document.write('<div class="pharmacy"><h2>' + Cookies.get('name') + '</h2></div>');
            printWindow.document.write('<p>Patient Name: ' + data.patientName + '</p>');
            printWindow.document.write('<p>Address: ' + data.address + '</p>');
            printWindow.document.write('<p>Phone: ' + data.phone + '</p>');
            printWindow.document.write('<p>Store Order Id: ' + data.storeOrderId + '</p>');


            if (data.numberOfPrescriptions) {
              if (data.numberOfPrescriptions == 1 && data['prescriptionNumber1'] != "") {
                printWindow.document.write('<p>Number Of Prescriptions: ' + data.numberOfPrescriptions + '</p>');
              } else if (data.numberOfPrescriptions > 1) {
                printWindow.document.write('<p>Number Of Prescriptions: ' + data.numberOfPrescriptions + '</p>');
              }
              for (let i = 1; i <= data.numberOfPrescriptions; i++) {
                const prescriptionNumber = data['prescriptionNumber' + i];
                if (prescriptionNumber) {
                  printWindow.document.write('<p>  - Prescription ' + i + ': ' + prescriptionNumber + '</p>');
                }
              }
            }
            if (data.scheduledDate) {
              printWindow.document.write('<p>Scheduled Date: ' + moment(data.scheduledDate).format('YYYY-MM-DD: hh:mm A') + '</p>');
            }

            printWindow.document.write('<p class="bold">Delivery Notes: ' + data.deliveryNotes + '</p>');
            printWindow.document.write('<img id="logo" class="logo" src="' + Logo + '" width="150" />');
            printWindow.document.write('</div>');
          });

          printWindow.document.write('</body></html>');
          printWindow.document.close();
          // Wait for the logo to load before printing
          printWindow.document.getElementById('logo').onload = function () {
            printWindow.print();
          };
        }
        redirectPage();
        return;
      }

      if (data.status === 'failure') {
        if (data.message) {
          toast.error(data.message);
        } else {
          toast.error('INVALID_PARAMETERS');
        }

        return;
      } else {
        redirectPage();
      }


    } catch (_) {
      toast.error('Something went wrong!');
    } finally {
      toggle(false);
      setLoading(false);
    }
  };

  const formatNumberCanadian = (number) => {
    const cleaned = ('' + number).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    if (number.startsWith('+')) {
      const cleaned = ('' + number).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '+' + match[1] + match[2] + '-' + match[3] + '-' + match[4];
      }

    }

  }
  const bgs = ['light', 'white'];

  if (deliveryCharges.length === 0 || Object.keys(deliveryCharges).length === 0) return null;
  return (
    <Modal isOpen={modal} toggle={() => toggle(false)}>
      <ModalHeader>Review Delivery Charges</ModalHeader>

      <ModalBody
        style={{
          maxHeight: 'calc(100vh - 210px)',
          overflowY: 'auto',
        }}
      >
        <Container>
          {deliveries.filter((delivery) => delivery).map((delivery, idx) => (
            <ReviewItem
              key={idx}
              bg={bgs[idx % bgs.length]}
              delivery={delivery}
              recurring={delivery.recurring}
              deliveryCharge={deliveryCharges[idx]}
            />
          ))}
          <br />
          <Row>
            <Col md={{ size: 3, offset: 9 }} className="bg-success pt-3 d-flex justify-content-end align-items-center">
              <h4 style={{ color: 'white', textAlign: 'end' }}>
                Total Fee : <b>${total}</b>
              </h4>
            </Col>
          </Row>
        </Container>
      </ModalBody>

      <ModalFooter>
        <Button disabled={loading || invalidDeliveryCharge} color="primary" onClick={() => submitOrders(true)}>
          Submit Order(s) & Print Label
        </Button>


        <Button disabled={loading || invalidDeliveryCharge} color="primary" onClick={() => submitOrders(false)}>
          Submit Order(s)
        </Button>


        <Button color="secondary" onClick={() => toggle(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function ReviewItem({ bg, delivery, deliveryCharge, recurring }) {
  return (
    <Row className={`pt-3 pb-3 bg-${bg} ${deliveryCharge.fee === 0 && 'bg-red'}`}>
      <Col md="9">
        <h3 style={{ marginBottom: 0, marginTop: 5 }}>{deliveryCharge.fee === 0 ? deliveryCharge.message : delivery.patientName}</h3>
        <p style={{ marginBottom: 0 }}>{
          delivery.apptOrUnit ? `${delivery.address.address}, ${delivery.apptOrUnitType || "Unit"} ${delivery.apptOrUnit}` : delivery.address.address
          // delivery.address.address
        }</p>
        {recurring && (
          <span style={{ padding: 3, backgroundColor: '#FFBF00', color: 'white', borderRadius: 5, fontSize: 13, marginBottom: 10 }}>
            Recurring Order
          </span>
        )}
      </Col>
      <Col md="3" className="d-flex justify-content-end align-items-center">
        <h1>${getPriceWithoutTax(deliveryCharge.fee)}</h1>
      </Col>
    </Row>
  );
}

export function getPriceWithoutTax(total) {
  const provinceTax = provinceTaxes[Cookies.get('province')] ? provinceTaxes[Cookies.get('province')] : 0;

  const subTotal = total / (1 + provinceTax / 100);

  console.log({ "provinceTax": provinceTax, "subTotal": subTotal });

  return (subTotal / 100).toFixed(2);
}

export function getPriceWithTax(total) {

  const provinceTax = provinceTaxes[Cookies.get('province')] ? provinceTaxes[Cookies.get('province')] : 0;

  return total * (1 + provinceTax / 100);
}
